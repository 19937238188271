const data = [
  {
    src: "https://archdesignsstudio.com/assets/projects-re/balanahouse/1.png",
    text: "Architecture 1",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/balanahouse/2.png",
    text: "Architecture 2",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/balanahouse/3.png",
    text: "Architecture 3",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/balanahouse/4.png",
    text: "Architecture 4",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/balanahouse/5.png",
    text: "Architecture 5",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/balanahouse/6.png",
    text: "Architecture 6",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/balanahouse/7.png",
    text: "Architecture 7",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/balanahouse/interiors/1.png",
    text: "Interiors 1",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/balanahouse/interiors/2.png",
    text: "Interiors 2",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/balanahouse/interiors/3.png",
    text: "Interiors 3",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/balanahouse/interiors/4.png",
    text: "Interiors 4",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/balanahouse/interiors/5.png",
    text: "Interiors 5",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/balanahouse/interiors/6.png",
    text: "Interiors 6",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/balanahouse/interiors/7.png",
    text: "Interiors 7",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/balanahouse/interiors/8.png",
    text: "Interiors 8",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/balanahouse/interiors/9.png",
    text: "Interiors 9",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/balanahouse/interiors/10.png",
    text: "Interiors 10",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/balanahouse/interiors/11.png",
    text: "Interiors 11",
  },
];

export default data;
